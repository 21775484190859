import request from '@/utils/request'


// 查询星座列表
export function listStarSign(query) {
  return request({
    url: '/platform/star-sign/list',
    method: 'get',
    params: query
  })
}

// 查询星座分页
export function pageStarSign(query) {
  return request({
    url: '/platform/star-sign/page',
    method: 'get',
    params: query
  })
}

// 查询星座详细
export function getStarSign(data) {
  return request({
    url: '/platform/star-sign/detail',
    method: 'get',
    params: data
  })
}

// 新增星座
export function addStarSign(data) {
  return request({
    url: '/platform/star-sign/add',
    method: 'post',
    data: data
  })
}

// 修改星座
export function updateStarSign(data) {
  return request({
    url: '/platform/star-sign/edit',
    method: 'post',
    data: data
  })
}

// 删除星座
export function delStarSign(data) {
  return request({
    url: '/platform/star-sign/delete',
    method: 'post',
    data: data
  })
}
